<template>
  <b-container class="m-0 p-0">
    <b-row>
      <b-col cols="8" class="m-0">
        <h4 class="subtitle-form">{{ $t("inputStock.details.percent_mineralizable_nitrogen") }}</h4>
      </b-col>
      <b-col cols="2" class="m-0 p-0 d-flex align-items-start">
        <b-input
          size="sm"
          type="number"
          min="0"
          max="100"
          class="m-0"
          @input="updateKeqN"
          :value="currentEditedEntry.percentMineralizableNitrogen || currentEntry.percentMineralizableNitrogen"
        ></b-input>
      </b-col>
      <b-col cols="2" class="m-0">
        <span> % </span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  methods: {
    updateKeqN(value) {
      this.$store.dispatch(this.storeEditAction, {
        percentMineralizableNitrogen: value ? parseInt(value) : null,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
